<template>
    <div>
        <section>
            <div class="container">
                <div
                    class="p-4 is-hidden-tablet filter-toggle-mobile"
                    @click="hidden = !hidden"
                >
                    Filters
                    <b-icon
                        class="is-pulled-right"
                        :icon="hidden ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </div>
                <div class="columns routes mb-4">
                    <div class="column pl-5 filters">
                        <div class="title is-4 mb-4 is-hidden-mobile">
                            Filters
                        </div>
                        <div
                            class="filter-controls"
                            :class="{
                                'hidden' : hidden,
                            }"
                        >
                            <div
                                class="mb-2 is-clickable reset-all"
                                @click="resetFilters();"
                            >
                                Show me everything
                            </div>
                            <div class="title is-5 mt-4">
                                Holiday Types
                            </div>
                            <div class="holiday-types">
                                <div
                                    v-for="type in holiday_types"
                                    class="mb-2 is-clickable holiday-type-wrapper"
                                    :class="holidayTypeClasses(type)"
                                    :key="type.name+'type'"
                                    @click="toggleHolidayType(type);"
                                >
                                    <div class="icon-wrapper has-text-centered">
                                        <span
                                            class="gr-icon gr-icon-tiny"
                                            :class="`gr-icon-${type.icon}`"
                                        ></span>
                                    </div>
                                    <div class="holiday-type-label ml-1" v-html="type.title"></div>
                                </div>
                            </div>
                            <div class="title is-5 mt-4">
                                How long for?
                            </div>
                            <div>
                                <b-field
                                    v-for="duration in durations"
                                    :key="`duration${duration.from}-${duration.to}`"
                                >
                                    <b-checkbox
                                        v-model="selected_durations"
                                        :native-value="`${duration.from}-${duration.to}`"
                                    >
                                        {{duration.title}}
                                    </b-checkbox>
                                </b-field>
                            </div>
                            <div class="title is-5 mt-4">
                                Trips including:
                            </div>
                            <div class="mb-2">
                                <b-field
                                    v-for="country in countries"
                                    :key="`country${country}`"
                                >
                                    <b-checkbox
                                        v-model="selected_countries"
                                        :native-value="country"
                                    >
                                        {{country}}
                                    </b-checkbox>
                                </b-field>
                            </div>
                            <div class="title is-5 mt-4">
                                Travel from:
                            </div>
                            <div class="mb-2">
                                <b-field
                                    v-for="departure in departures"
                                    :key="`departure${departure}`"
                                >
                                    <b-checkbox
                                        v-model="selected_departure"
                                        :native-value="departure"
                                    >
                                        {{departure}}
                                    </b-checkbox>
                                </b-field>
                            </div>
                            <div class="title is-5 mt-4">
                                Travelling by
                            </div>
                            <div class="mb-2">
                                <b-radio v-model="include_ferry"
                                    name="include_ferry"
                                    native-value="0">
                                    Train Only
                                </b-radio>
                                <br />
                                <b-radio v-model="include_ferry"
                                    name="include_ferry"
                                    native-value="1">
                                    Ferry and/or Train
                                </b-radio>
                            </div>

                            <div class="title is-5 mt-4">
                                I would rather
                            </div>
                            <div class="mb-2">
                                <div class="search-refinement">
                                    <div
                                        v-for="refinement in search_refinements"
                                        class="mb-2 is-clickable search-refinement-wrapper"
                                        :key="refinement.name+'refinement'"
                                    >
                                        <b-checkbox
                                            v-model="selected_refinements"
                                            :native-value="refinement.name"
                                        >
                                            {{refinement.title}}
                                        </b-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-three-quarters search">
                        <div class="title is-4 pl-3">
                            <template v-if="routes.length">
                                We have found you {{routes.length}} Good Routes:
                            </template>
                            <template v-else-if="loading_routes">
                                Loading...
                            </template>
                            <template v-else>
                                Oh no, that's not good!
                            </template>
                        </div>
                        <div class="routes-list">
                            <RouteCard
                                :route="route"
                                v-for="route in routes"
                                :key="route.id"
                            ></RouteCard>
                            <div
                                v-if="!loading_routes && routes.length === 0"
                                class="column"
                            >
                                <div class="notification is-warning">
                                    Oh no! That's not good.
                                    We don't have a route that matches your criteria (yet).
                                    Try amending your filters.
                                </div>
                            </div>
                            <b-loading
                                :is-full-page="false"
                                v-model="loading_routes"
                                :can-cancel="false"
                            ></b-loading>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import RouteCard from '@/components/RouteCard.vue';

import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';

export default {
    name: 'Routes',
    components: {
        RouteCard,
    },
    data() {
        return {
            selected_countries: [],
            selected_departure: [],
            selected_durations: [],
            selected_refinements: [],
            selected_location: '',
            include_ferry: '1',
            hidden: true,
        };
    },
    async mounted() {
        // if no type supplied, tick them all
        if (this.$route.query.types === '' || typeof this.$route.query.types === 'undefined') {
            this.selectHolidayType({ name: 'everything' });
        }

        // if no refinements supplied, tick them all
        if (this.$route.query.refinements === '' || typeof this.$route.query.refinements !== 'undefined') {
            this.selected_refinements = this.$route.query.refinements.split(',');
        }

        // handle countries from the URL
        if (this.$route.query.country !== '' && typeof this.$route.query.country !== 'undefined') {
            this.selected_countries = this.$route.query.country.split(',');
        }

        // if we have a location from the url, add its country to the list
        if (this.$route.query.location !== '' && typeof this.$route.query.location !== 'undefined') {
            this.selected_countries = this.selected_countries.concat(this.getCountriesFromLocations(this.$route.query.location.split(',')));
            this.selected_location = this.$route.query.location;
        }

        // if we have durations from the url, set them (durations will be in the form 1-3, 1- or -4)
        // meaning 1-3 days, 1 or more days, up to 4 days
        if (this.$route.query.durations !== '' && typeof this.$route.query.durations !== 'undefined') {
            this.selected_durations = this.$route.query.durations.split(',');
        }

        // train or ferry and train?
        if (typeof this.$route.query.include_ferry !== 'undefined' && this.$route.query.include_ferry !== '') {
            this.include_ferry = this.$route.query.include_ferry;
        }

        this.fetchRoutes(this.buildURLParams());
    },
    computed: {
        ...mapState({
            loading_routes: (state) => state.route.is_loading,
            routes: (state) => state.route.arr_routes,
            holiday_types: (state) => state.tag.holiday_types,
            search_refinements: (state) => state.tag.search_refinements,
            locations: (state) => state.location.arr_location,
            durations: (state) => state.route.durations,
        }),
        ...mapGetters('location', [
            'split_destinations',
            'departures',
            'countries',
        ]),
        // return array of holiday types that have been selected
        selected_types() {
            return this.holiday_types.filter((type) => type.selected);
        },
    },
    watch: {
        single_location() {
            this.setURLParams();
        },
        include_ferry() {
            this.setURLParams();
        },
        selected_countries() {
            this.setURLParams();
        },
        selected_departure() {
            this.setURLParams();
        },
        selected_durations() {
            this.setURLParams();
        },
        selected_refinements() {
            this.setURLParams();
        },
    },
    methods: {
        ...mapMutations('tag', [
            'selectHolidayType',
        ]),
        ...mapActions('route', [
            'fetchRoutes',
        ]),
        getCountriesFromLocations(locations) {
            // return the countries that match the supplied locations
            const arr_countries = [];
            this.locations.forEach((location) => {
                if (locations.indexOf(location.name) !== -1) {
                    arr_countries.push(location.country);
                }
            });
            return arr_countries;
        },
        // holiday type classes
        holidayTypeClasses(type) {
            const arr_classes = [];
            if (type.selected) {
                arr_classes.push('selected');
            }
            return arr_classes;
        },
        toggleHolidayType(selected_type) {
            // toggle the type
            this.selectHolidayType(selected_type);
            this.setURLParams();
        },
        resetFilters() {
            this.deSelectAllTypes();
            this.deSelectAllCountries();
            this.deSelectAllDurations();
            this.deSelectAllDepartures();
            this.include_ferry = 1;
            this.selected_location = '';
        },
        deSelectAllTypes() {
            this.selectHolidayType({ name: 'everything' });
        },
        deSelectAllCountries() {
            this.selected_countries = [];
        },
        deSelectAllDurations() {
            this.selected_durations = [];
        },
        deSelectAllDepartures() {
            this.selected_departure = [];
        },
        setURLParams() {
            const selected_type_names = this.selected_types.map((type) => type.name);
            const new_types = this.selected_types.length === this.holiday_types.length
                ? ''
                : selected_type_names.join(',');

            const new_refinements = this.selected_refinements.length
                === this.search_refinements.length
                ? ''
                : this.selected_refinements.join(',');
            const new_country = this.selected_countries.length === this.countries.length
                ? ''
                : this.selected_countries.join(',');
            const new_durations = this.selected_durations.length === this.durations.length
                ? ''
                : this.selected_durations.join(',');
            const new_departure = this.selected_departure.length === this.departures.length
                ? ''
                : this.selected_departure.join(',');
            const new_location = this.selected_location;
            const new_include_ferry = this.include_ferry;

            const params_changed = new_types !== this.$route.query.types
                || new_refinements !== this.$route.query.refinements
                || new_durations !== this.$route.query.durations
                || new_departure !== this.$route.query.departure
                || new_country !== this.$route.query.country
                || new_include_ferry.toString() !== this.$route.query.include_ferry
                || new_location !== this.$route.query.location;

            // if the params have changed, update URL and reload routes
            if (params_changed) {
                this.$router.replace(
                    {
                        path: 'routes',
                        query: {
                            types: new_types,
                            refinements: new_refinements,
                            durations: new_durations,
                            departure: new_departure,
                            country: new_country,
                            location: new_location,
                            include_ferry: new_include_ferry,
                        },
                    },
                );
                this.fetchRoutes(this.buildURLParams());
            }
        },
        buildURLParams() {
            return {
                types: this.selected_types.map((type) => type.name).join(','),
                location: this.$route.query.location,
                refinements: this.selected_refinements.join(','),
                country: this.selected_countries.join(','),
                departure: this.selected_departure.join(','),
                durations: this.selected_durations.join(','),
                include_ferry: this.include_ferry,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    $icon-width: 30px;

    .page-header {
        padding-top: $navbar-desktop-height;
    }

    .reset-all {
        text-decoration: underline;
    }

    .holiday-types {
        .holiday-type-wrapper {
            display: flex;
            .icon-wrapper {
                width: $icon-width;
            }
            .holiday-type-label {
                line-height: $icon-width;
            }
        }
        .selected {
            .holiday-type-label {
                color: $primary;
            }
        }
    }

    .routes-list {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
    }

    .filter-toggle-mobile {
        background-color: $skyBlue;
    }

    @media screen and (max-width: $tablet) {
        .filters {
            .filter-controls.hidden {
                display: none;
            }
        }
    }

    @media screen and (min-width: $tablet) {
        .routes {
            margin-top: 1.5em;
        }
    }
</style>
